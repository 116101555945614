import React, { useEffect } from "react";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import Login from "../components/Login";
import Navigation from "../components/Navigation";

function LoginPage() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <>
      <Navigation />
      <Login />
      <Contact />
      <Footer />
    </>
  );
}

export default LoginPage;
