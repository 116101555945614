import React, { useEffect } from "react";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import FreelanceRecruiterReg from "../components/FreelanceRecruiterReg";
import Navigation from "../components/Navigation";

function FreelanceRecruiterRegPage() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div>
      <Navigation />
      <FreelanceRecruiterReg />
      <Contact />
      <Footer />
    </div>
  );
}

export default FreelanceRecruiterRegPage;
