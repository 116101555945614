import React, { useEffect } from "react";
import ConsultantAndCareerAdvisorReg from "../components/ConsultantAndCareerAdvisorReg";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import Navigation from "../components/Navigation";

function ConsultantAndCareerAdvisorRegPage() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div>
      <Navigation />
      <ConsultantAndCareerAdvisorReg />
      <Contact />
      <Footer />
    </div>
  );
}

export default ConsultantAndCareerAdvisorRegPage;
