import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row, Pagination, Button } from "react-bootstrap";

class careerAdvisor extends Component {
  render() {
    return (
      <Fragment>
        <div className="page__bg">
          <Container>
            <p className="pageSection__title pt-5">Career Advisor</p>
            <p className="greyBar"></p>
            <p className="jobCategory__checkBoxSection_para">
              This is another unique initiative by TalenTracker. Huge number of
              potential job seekers and entry level professionals remains in
              confusion about their career path and choices due to lack of
              access to professionals, industries and job market scenario. The
              serve this unmet needs, TTL has created this unique platform where
              there will be a huge number of registered career advisors from
              different industries and functions to offer one-to-one career
              advisory services. <br></br>
              <br></br>
              <span className="jobCategory__checkBoxSection_para">
                <i>
                  Approximately 1.2 million graduates from different disciplines
                  enters the employment market every year!
                </i>
              </span>
              <br></br>
              <br></br>
              Aspiring service advice seekers will be able to take appointments
              from their preferred career advisors through TTL platform for 1-2
              hours to take career advises, interview preparation and other
              queries regarding job market and jobs.
            </p>
            <Link to="/ConsultantAndCareerAdvisorRegPage">
              <button className="talentTracker__btn freelance__btn">
                join us
              </button>
            </Link>

            {/* <div className="jobCategory__checkBoxSection mt-4">
                            <Row>
                                <Col lg="4" md="4" sm="12">
                                    <div className="p-4 jobCategory__checkBoxSection--responsive jobCategory__checkBoxSection--responsiveFirst">
                                        <input type="checkbox"/>
                                        <label className="checkbox__label">Finance</label><br/>

                                        <input type="checkbox"/>
                                        <label className="checkbox__label"> Accounting </label><br/>

                                        <input type="checkbox"/>
                                        <label className="checkbox__label"> Supply Chain </label><br/>
                                    </div>
                                </Col>

                                <Col lg="4" md="4" sm="12">
                                    <div className="p-4 jobCategory__checkBoxSection--responsive">
                                        <input type="checkbox"/>
                                        <label className="checkbox__label"> Trade Marketing</label><br/>

                                        <input type="checkbox"/>
                                        <label className="checkbox__label"> Business Management </label><br/>

                                        <input type="checkbox"/>
                                        <label className="checkbox__label"> Marketing </label><br/>
                                    </div>
                                </Col>

                                <Col lg="4" md="4" sm="12">
                                    <div className="p-4 jobCategory__checkBoxSection--responsive">
                                        <input type="checkbox"/>
                                        <label className="checkbox__label"> Sales</label><br/>

                                        <input type="checkbox"/>
                                        <label className="checkbox__label"> HR </label><br/>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <Row className=" consultant__cardSectionArea mt-5">
                            <Col lg="4" md="6" sm="12">
                                <div className="careerAdvisor__cardArea">
                                    <img className="careerAdvisor__cardImg" src={ConsultantImage} alt="consultantImg"/>
                                    <div className="careerAdvisor__cardItems">
                                        <p className="careerAdvisor__name careerAdvisor__des">Name</p>
                                        <p className="careerAdvisor__companyName careerAdvisor__des">Company Name</p>
                                        <p className="careerAdvisor__designation careerAdvisor__des">Designation</p>
                                        <p className="careerAdvisor__specialistField careerAdvisor__des">Career Advisor Specialist</p>
                                        <p className="careerAdvisor__charge careerAdvisor__des">Hourly Charge: 1000 BDT</p>
                                        <Button className="careerAdvisor__connect--btn details__btn mt-2">Connect</Button>
                                    </div>
                                </div>
                            </Col>

                            <Col lg="4" md="6" sm="12">
                                <div className="careerAdvisor__cardArea">
                                    <img className="careerAdvisor__cardImg" src={ConsultantImage} alt="consultantImg"/>
                                    <div className="careerAdvisor__cardItems">
                                        <p className="careerAdvisor__name careerAdvisor__des">Name</p>
                                        <p className="careerAdvisor__companyName careerAdvisor__des">Company Name</p>
                                        <p className="careerAdvisor__designation careerAdvisor__des">Designation</p>
                                        <p className="careerAdvisor__specialistField careerAdvisor__des">Career Advisor Specialist</p>
                                        <p className="careerAdvisor__charge careerAdvisor__des">Hourly Charge: 1000 BDT</p>
                                        <Button className="careerAdvisor__connect--btn details__btn mt-2">Connect</Button>
                                    </div>
                                </div>  
                            </Col>

                            <Col lg="4" md="6" sm="12">
                                <div className="careerAdvisor__cardArea">
                                    <img className="careerAdvisor__cardImg" src={ConsultantImage} alt="consultantImg"/>
                                    <div className="careerAdvisor__cardItems">
                                        <p className="careerAdvisor__name careerAdvisor__des">Name</p>
                                        <p className="careerAdvisor__companyName careerAdvisor__des">Company Name</p>
                                        <p className="careerAdvisor__designation careerAdvisor__des">Designation</p>
                                        <p className="careerAdvisor__specialistField careerAdvisor__des">Career Advisor Specialist</p>
                                        <p className="careerAdvisor__charge careerAdvisor__des">Hourly Charge: 1000 BDT</p>
                                        <Button className="careerAdvisor__connect--btn details__btn mt-2">Connect</Button>
                                    </div>
                                </div>  
                            </Col>

                            <Col lg="4" md="6" sm="12">
                                <div className="careerAdvisor__cardArea">
                                    <img className="careerAdvisor__cardImg" src={ConsultantImage} alt="consultantImg"/>
                                    <div className="careerAdvisor__cardItems">
                                        <p className="careerAdvisor__name careerAdvisor__des">Name</p>
                                        <p className="careerAdvisor__companyName careerAdvisor__des">Company Name</p>
                                        <p className="careerAdvisor__designation careerAdvisor__des">Designation</p>
                                        <p className="careerAdvisor__specialistField careerAdvisor__des">Career Advisor Specialist</p>
                                        <p className="careerAdvisor__charge careerAdvisor__des">Hourly Charge: 1000 BDT</p>
                                        <Button className="careerAdvisor__connect--btn details__btn mt-2">Connect</Button>
                                    </div>
                                </div>  
                            </Col>

                            <Col lg="4" md="6" sm="12">
                                <div className="careerAdvisor__cardArea">
                                    <img className="careerAdvisor__cardImg" src={ConsultantImage} alt="consultantImg"/>
                                    <div className="careerAdvisor__cardItems">
                                        <p className="careerAdvisor__name careerAdvisor__des">Name</p>
                                        <p className="careerAdvisor__companyName careerAdvisor__des">Company Name</p>
                                        <p className="careerAdvisor__designation careerAdvisor__des">Designation</p>
                                        <p className="careerAdvisor__specialistField careerAdvisor__des">Career Advisor Specialist</p>
                                        <p className="careerAdvisor__charge careerAdvisor__des">Hourly Charge: 1000 BDT</p>
                                        <Button className="careerAdvisor__connect--btn details__btn mt-2">Connect</Button>
                                    </div>
                                </div>  
                            </Col>

                            <Col lg="4" md="6" sm="12">
                                <div className="careerAdvisor__cardArea">
                                    <img className="careerAdvisor__cardImg" src={ConsultantImage} alt="consultantImg"/>
                                    <div className="careerAdvisor__cardItems">
                                        <p className="careerAdvisor__name careerAdvisor__des">Name</p>
                                        <p className="careerAdvisor__companyName careerAdvisor__des">Company Name</p>
                                        <p className="careerAdvisor__designation careerAdvisor__des">Designation</p>
                                        <p className="careerAdvisor__specialistField careerAdvisor__des">Career Advisor Specialist</p>
                                        <p className="careerAdvisor__charge careerAdvisor__des">Hourly Charge: 1000 BDT</p>
                                        <Button className="careerAdvisor__connect--btn details__btn mt-2">Connect</Button>
                                    </div>
                                </div>  
                            </Col>

                            <Col lg="4" md="6" sm="12">
                                <div className="careerAdvisor__cardArea">
                                    <img className="careerAdvisor__cardImg" src={ConsultantImage} alt="consultantImg"/>
                                    <div className="careerAdvisor__cardItems">
                                        <p className="careerAdvisor__name careerAdvisor__des">Name</p>
                                        <p className="careerAdvisor__companyName careerAdvisor__des">Company Name</p>
                                        <p className="careerAdvisor__designation careerAdvisor__des">Designation</p>
                                        <p className="careerAdvisor__specialistField careerAdvisor__des">Career Advisor Specialist</p>
                                        <p className="careerAdvisor__charge careerAdvisor__des">Hourly Charge: 1000 BDT</p>
                                        <Button className="careerAdvisor__connect--btn details__btn mt-2">Connect</Button>
                                    </div>
                                </div>  
                            </Col>

                            <Col lg="4" md="6" sm="12">
                                <div className="careerAdvisor__cardArea">
                                    <img className="careerAdvisor__cardImg" src={ConsultantImage} alt="consultantImg"/>
                                    <div className="careerAdvisor__cardItems">
                                        <p className="careerAdvisor__name careerAdvisor__des">Name</p>
                                        <p className="careerAdvisor__companyName careerAdvisor__des">Company Name</p>
                                        <p className="careerAdvisor__designation careerAdvisor__des">Designation</p>
                                        <p className="careerAdvisor__specialistField careerAdvisor__des">Career Advisor Specialist</p>
                                        <p className="careerAdvisor__charge careerAdvisor__des">Hourly Charge: 1000 BDT</p>
                                        <Button className="careerAdvisor__connect--btn details__btn mt-2">Connect</Button>
                                    </div>
                                </div>  
                            </Col>

                            <Col lg="4" md="6" sm="12">
                                <div className="careerAdvisor__cardArea">
                                    <img className="careerAdvisor__cardImg" src={ConsultantImage} alt="consultantImg"/>
                                    <div className="careerAdvisor__cardItems">
                                        <p className="careerAdvisor__name careerAdvisor__des">Name</p>
                                        <p className="careerAdvisor__companyName careerAdvisor__des">Company Name</p>
                                        <p className="careerAdvisor__designation careerAdvisor__des">Designation</p>
                                        <p className="careerAdvisor__specialistField careerAdvisor__des">Career Advisor Specialist</p>
                                        <p className="careerAdvisor__charge careerAdvisor__des">Hourly Charge: 1000 BDT</p>
                                        <Button className="careerAdvisor__connect--btn details__btn mt-2">Connect</Button>
                                    </div>
                                </div>  
                            </Col>

                            <Pagination className="talentTracker__pagination mt-5">
                                <Pagination.First />
                                <Pagination.Prev />
                                <Pagination.Item>{1}</Pagination.Item>
                                <Pagination.Ellipsis />

                                <Pagination.Item>{10}</Pagination.Item>
                                <Pagination.Item>{11}</Pagination.Item>
                                <Pagination.Item active>{12}</Pagination.Item>
                                <Pagination.Item>{13}</Pagination.Item>
                                <Pagination.Item disabled>{14}</Pagination.Item>

                                <Pagination.Ellipsis />
                                <Pagination.Item>{20}</Pagination.Item>
                                <Pagination.Next />
                                <Pagination.Last />
                            </Pagination>
                        </Row> */}
          </Container>
        </div>
      </Fragment>
    );
  }
}

export default careerAdvisor;
