import React, { useEffect } from "react";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import Navigation from "../components/Navigation";
import TrainingAndDevelopment from "../components/TrainingAndDevelopment";

function TrainingAndDevelopmentPage() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div>
      <Navigation />
      <TrainingAndDevelopment />
      <Contact />
      <Footer />
    </div>
  );
}

export default TrainingAndDevelopmentPage;
