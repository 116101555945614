import React, { useEffect } from "react";
import Footer from "../components/Footer";
import Navigation from "../components/Navigation";
import AboutTalentTracker from "../components/AboutTalentTracker";
import Contact from "../components/Contact";

function AboutTalentTrackerPage() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div>
      <Navigation />
      <AboutTalentTracker />
      <Contact />
      <Footer />
    </div>
  );
}

export default AboutTalentTrackerPage;
