import React from "react";
import { Container } from "react-bootstrap";
import "../public/assets/scss/Services.css";
import Slider from "react-slick";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";

function Services() {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  return (
    <section id="service" className="service pt-5">
      <Container>
        <h2 className="service__title mb-3">Services</h2>
        <p className="service__description">
          TalenTracker offers a wide range of HR services that include but are
          not limited to internal and external HR services and also support
          services for HR and Administration functions. To perform these
          services, TTL has got a team of experts who are seasoned in offering
          these services with precision and efficacy.
        </p>
        <div className="mt-4 service__background">
          <div className="service__overlay py-5">
            <Slider {...settings}>
              <div>
                <ul className="services-items">
                  <li className="my-3 list-none">
                    <Link to="/hrServicePage">
                      <span>
                        <IoIosArrowForward />
                      </span>
                      HR Services
                    </Link>
                  </li>
                  <li className="my-3 list-none">
                    <Link to="/HrAndPayrollOutsourcingPage">
                      <span>
                        <IoIosArrowForward />
                      </span>
                      HR & Payroll Outsourcing
                    </Link>
                  </li>
                  <li className="my-3 list-none">
                    <Link to="/TalentSearchAndOutplacementPage">
                      <span>
                        <IoIosArrowForward />
                      </span>
                      Talent Search & Outplacement
                    </Link>
                  </li>
                </ul>
              </div>
              <div>
                <ul className="services-items">
                  <li className="my-3 list-none">
                    <Link to="/HrConsultancyServicesPage">
                      <span>
                        <IoIosArrowForward />
                      </span>
                      HR Consultancy Services
                    </Link>
                  </li>
                  <li className="my-3 list-none">
                    <Link to="/CareerCoachingAndMentoringPage">
                      <span>
                        <IoIosArrowForward />
                      </span>
                      Career Coaching and Mentoring
                    </Link>
                  </li>
                  <li className="my-3 list-none">
                    <Link to="/TrainingAndDevelopmentPage">
                      <span>
                        <IoIosArrowForward />
                      </span>
                      Training & Development
                    </Link>
                  </li>
                </ul>
              </div>
              <div>
                <ul className="services-items">
                  <li className="my-3 list-none">
                    <Link to="/HrAutomationAndHrisConsultancyPage">
                      <span>
                        <IoIosArrowForward />
                      </span>
                      HR Automation & HRIS Consultancy
                    </Link>
                  </li>
                  <li className="my-3 list-none">
                    <Link to="/EmployeeBenefitSchemeDesignAndApprovalPage">
                      <span>
                        <IoIosArrowForward />
                      </span>
                      Employee Benefit Scheme Design and Approval
                    </Link>
                  </li>
                  <li className="my-3 list-none">
                    <Link to="/BusinessSupportServicesPage">
                      <span>
                        <IoIosArrowForward />
                      </span>
                      Business Support Services
                    </Link>
                  </li>
                </ul>
              </div>
              <div>
                <ul className="services-items">
                  <li className="my-3 list-none">
                    <Link to="/ExpatriateAffairsManagementPage">
                      <span>
                        <IoIosArrowForward />
                      </span>
                      Expatriate Affairs Management
                    </Link>
                  </li>
                  <li className="my-3 list-none">
                    <Link to="/LicensingServicesPage">
                      <span>
                        <IoIosArrowForward />
                      </span>
                      Licensing Services
                    </Link>
                  </li>
                  <li className="my-3 list-none">
                    <Link to="/PersonalTaxManagementPage">
                      <span>
                        <IoIosArrowForward />
                      </span>
                      Personal Tax Management
                    </Link>
                  </li>
                </ul>
              </div>
            </Slider>
          </div>
        </div>
      </Container>
    </section>
  );
}

export default Services;
