import axios from "axios";
import React, { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function FreelanceRecruiterReg() {
  const proxy = process.env.REACT_APP_PROXY;
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [address, setAddress] = useState("");

  function form_submit() {
    var formData = new FormData();

    formData.append("name", name);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("password", password);
    formData.append("address", address);

    axios
      .post(`${proxy}/frecuiter`, formData)
      .then((res, err) => {
        if (err) {
          console.log(err);
        } else {
          window.Email.send({
            SecureToken: process.env.REACT_APP_SECURETOKEN,
            To: res.data.email,
            From: process.env.REACT_APP_EMAIL_FROM,
            Subject: "From Talentracker Official Site",
            Body: `Your have succesfully registered with Talentracker. <br/>
            Name : ${res.data.name} <br>
            Email : ${res.data.email} <br>
            Phone : ${res.data.phone} <br>
            Thank you.`,
          });
          window.Email.send({
            SecureToken: process.env.REACT_APP_SECURETOKEN,
            To: "care@talentracker.com.bd",
            From: process.env.REACT_APP_EMAIL_FROM,
            Subject: "New Freelance Recruiter Registration",
            Body: `
            Name : ${res.data.name} <br>
            Email : ${res.data.email} <br>
            Phone : ${res.data.phone} <br>
            `,
          });
          //   console.log(res);
          navigate("/");
        }
      })
      .catch((err) => {
        document.getElementById("warning").innerHTML =
          "Something Went Wrong.Please Try Again With Unique Email ";
      });
  }

  return (
    <>
      <div className="page__bg">
        <div className="freelanceRecruiterReg__section">
          <Container>
            <p className="pageSection__title pt-5">Freelance Recruiter</p>
            <p className="greyBar"></p>

            <Form
              //   action="https://halcyonbd.com/talent/frecuiter"
              //   method="POST"
              onSubmit={(event) => {
                event.preventDefault();
                form_submit();
              }}
            >
              <Row className="mt-5">
                <Col lg="6" md="6" sm="12">
                  <Form.Group>
                    <Form.Label className="from__label">Name</Form.Label>
                    <Form.Control
                      className="formArea formAreaBorder"
                      name="name"
                      placeholder="Enter Full Name"
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label className="from__label">
                      Email Address
                    </Form.Label>
                    <Form.Control
                      className="formArea formAreaBorder"
                      type="email"
                      name="email"
                      placeholder="Enter Your Email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label className="from__label">Address</Form.Label>
                    <Form.Control
                      className="formArea formAreaBorder"
                      name="address"
                      type="text"
                      placeholder="Address"
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </Form.Group>
                </Col>

                <Col lg="6" md="6" sm="12">
                  <Form.Group>
                    <Form.Label className="from__label">
                      Mobile Number
                    </Form.Label>
                    <Form.Control
                      className="formArea formAreaBorder"
                      name="phone"
                      placeholder="+880xxxx xxxxxx"
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </Form.Group>

                  <>
                    <Form.Group as={Col}>
                      <Form.Label className="from__label">Password</Form.Label>
                      <Form.Control
                        className="formArea formAreaBorder"
                        name="password"
                        type="password"
                        placeholder="Password"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </Form.Group>

                    <Form.Group as={Col}>
                      <Form.Label className="from__label">
                        Confirm Password
                      </Form.Label>
                      <Form.Control
                        className="formArea formAreaBorder"
                        name="password"
                        type="password"
                        placeholder="Confirm Password"
                      />
                    </Form.Group>
                  </>
                </Col>
                <Col lg="6" md="6" sm="12">
                  <div className="mt-5">
                    <input type="checkbox" required />
                    <label className="checkbox__label ms-2">
                      {" "}
                      I agree to the Talen Tracker Terms of use. Terms &
                      Conditions
                    </label>
                    <br />

                    <input type="checkbox" />
                    <label className="checkbox__label ms-2">
                      {" "}
                      Subscribe to Talen Tracker Newsletter.
                    </label>
                    <br />
                  </div>
                </Col>

                <Col lg="6" md="6" sm="12">
                  <div className="mt-5">
                    <Button
                      className="grdiantBtn freelanceRecruiterReg__btn"
                      type="submit"
                    >
                      {" "}
                      SUBMIT{" "}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </Container>
        </div>
      </div>
    </>
  );
}

export default FreelanceRecruiterReg;
