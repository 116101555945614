import axios from "axios";
import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { Container, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
const proxy = process.env.REACT_APP_PROXY;
export default function JobDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [jobDetails, setjobDetails] = useState([]);
  const [isloading, setIsloading] = useState(true);

  useEffect(() => {
    axios.get(`${proxy}/joblist/${id}`).then((res, err) => {
      if (err) {
        console.log(err);
      } else {
        setjobDetails(res.data);
        setIsloading(false);
      }
    });
  }, [id]);
  // console.log(jobDetails)

  if (isloading) {
    return <div>Loading</div>;
  }
  return (
    <Fragment>
      <Container>
        <p className="pageSection__title pt-5">Job Details</p>
        <p className="greyBar"></p>
        <p className="mt-5 jobDetails__title">
          Job Title : {jobDetails.job_title}
        </p>
        <p className="jobDetails__des">Category : {jobDetails.category}</p>
        <p className="jobDetails__des">Vacancy : {jobDetails.vacancy}</p>
        <p className="jobDetails__des">Salary Range : {jobDetails.salary}</p>

        <iframe
          className="mt-5"
          title="p"
          src={`https://talentracker.com.bd/project/public/images/${jobDetails.details_image}`}
          frameBorder={0}
        ></iframe>

        <div className="text-center mt-5">
          <Button
            onClick={() => {
              navigate("/createAccountPage");
            }}
            className="grdiantBtn jobDetails__btn"
            type="submit"
          >
            {" "}
            Apply Now{" "}
          </Button>
        </div>
      </Container>
    </Fragment>
  );
}
