import React, { Component, Fragment } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import emailIcon from "../public/assets/images/emailIcon.webp";
import locationIcon from "../public/assets/images/locationIcon.webp";
import phoneIcon from "../public/assets/images/phoneIcon.png";
import sendMessageIcon from "../public/assets/images/sendMessage.6a775935.webp";

function formfillup() {
  // Secuirity token f134dd27-a459-477f-a259-47182b477905 for website@halcyonbd.com
  window.Email.send({
    SecureToken: process.env.REACT_APP_SECURETOKEN,
    To: "care@talentracker.com.bd",
    From: process.env.REACT_APP_EMAIL_FROM,
    Subject: "From Talentracker Official Site",
    Body: `
    Name : ${document.getElementsByName("name")[0].value} <br>
    Email : ${document.getElementsByName("email")[0].value} <br>
    Phone : ${document.getElementsByName("phone")[0].value} <br>
    Designation : ${document.getElementsByName("designation")[0].value} <br>
    Organization : ${
      document.getElementsByName("organization")[0].value
    } <br><br><br>
    Message : ${document.getElementsByName("message")[0].value}`,
  }).then((message) => {
    if (message === "OK") {
      document.getElementById("warning").innerHTML =
        "  Message Succesfully Sent";
    } else {
      console.log(message);
      document.getElementById("warning").innerHTML =
        "  Something Went Wrong. Please Try Again Later";
    }
    document.getElementById("btn").innerHTML = "SEND MESSAGE";
    document.getElementById("btn").style.cursor = "pointer";
    document.getElementById("btn").style.pointerEvents = "auto";
  });
}

class Contact extends Component {
  render() {
    return (
      <Fragment>
        <Container className="mt-5">
          <p className="client__title">Contact Us</p>
          <p className="greyBar"></p>
        </Container>

        <div className="contact__sectionArea mt-4">
          <Container>
            <Row className="text-center">
              <Col lg="4" md="4" sm="12">
                <a href="mailto:care@talentracker.com.bd">
                  <img className="mt-5 w-48 mb-3" src={emailIcon} alt="icon" />
                  <p className="contact__details"> care@talentracker.com.bd </p>
                </a>
              </Col>

              <Col lg="4" md="4" sm="12">
                <a
                  title="+8801847293000"
                  href="https://api.whatsapp.com/send?phone=+8801847293000&text="
                >
                  <img className="mt-5 w-48 mb-3" src={phoneIcon} alt="icon" />
                  <p className="contact__details"> WhatsApp</p>
                </a>
              </Col>

              <Col lg="4" md="4" sm="12">
                <a href="https://www.google.com/maps/dir//TalenTracker+Limited+317+Rd+No+4+Dhaka/@23.8346981,90.3669066,19z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3755c0d6f3cd97f1:0xf61907388a238e9c!2m2!1d90.3669066!2d23.8346981">
                  <img
                    className="mt-5 w-48 mb-3"
                    src={locationIcon}
                    alt="icon"
                  />
                  <p className="contact__details">
                    House-1(2nd Floor), Road-3, Block-A, Mirpur-11,
                    <br /> Begum Rokeya Avenue, Dhaka-1216
                  </p>
                </a>
              </Col>
            </Row>

            <Row className="mt-5">
              <Col lg="7" md="7" sm="12">
                <Form
                  onSubmit={(event) => {
                    document.getElementById("btn").innerHTML = "Sending...";
                    document.getElementById("btn").style.cursor = "not-allowed";
                    document.getElementById("btn").style.pointerEvents = "none";
                    event.preventDefault();
                    formfillup();
                  }}
                >
                  <Form.Group>
                    <Form.Control
                      required
                      className="formArea"
                      name="name"
                      placeholder="Enter Full Name"
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Control
                      required
                      name="email"
                      className="formArea"
                      type="email"
                      placeholder="Enter Email Address"
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Control
                      required
                      name="phone"
                      className="formArea"
                      placeholder="Enter Phone Number"
                    />
                  </Form.Group>

                  <>
                    <Form.Group as={Col}>
                      <Form.Control
                        name="designation"
                        className="formArea"
                        placeholder="Designation (Optional)"
                      />
                    </Form.Group>

                    <Form.Group as={Col}>
                      <Form.Control
                        name="organization"
                        className="formArea"
                        placeholder="Organization Name (Optional)"
                      />
                    </Form.Group>
                  </>

                  <Form.Group>
                    <Form.Control
                      required
                      name="message"
                      className="formArea"
                      placeholder="Your Message"
                      as="textarea"
                      rows={3}
                    />
                  </Form.Group>

                  <Button
                    id="btn"
                    className="grdiantBtn contact__btn"
                    type="submit"
                  >
                    <span>
                      <img src={sendMessageIcon} alt="submit-btn" />
                    </span>
                    SEND MESSAGE
                  </Button>
                  <span id="warning" className="warning"></span>
                </Form>
              </Col>

              <Col lg="5" md="5" sm="12">
                {/* <iframe className="contact__map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3651.214932281053!2d90.3623941143853!3d23.77535948457705!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755b93daff949d3%3A0xf5424a1e248e910b!2sHalcyon!5e0!3m2!1sen!2sbd!4v1611571181713!5m2!1sen!2sbd" frameborder="0"  allowfullscreen="" aria-hidden="false" tabindex="0"></iframe> */}
                <iframe
                  title="map"
                  className="contact__map"
                  // src="https://maps.google.com/maps?q=91%20Senpara%20Parbata%20Lane,%20Dacca&t=&z=13&ie=UTF8&iwloc=&output=embed"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d912.3867805705139!2d90.36635808801127!3d23.834698099029502!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c0d6f3cd97f1%3A0xf61907388a238e9c!2sTalenTracker%20Limited!5e0!3m2!1sen!2sbd!4v1624429434050!5m2!1sen!2sbd"
                  frameborder="0"
                  allowfullscreen=""
                  aria-hidden="false"
                  tabindex="0"
                ></iframe>
              </Col>
            </Row>
          </Container>
        </div>
      </Fragment>
    );
  }
}

export default Contact;
