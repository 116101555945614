import axios from "axios";
import React, { Component, Fragment, useState } from "react";
import { useEffect } from "react";
import { Col, Container, Row, Pagination, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import JobIcon from "../public/assets/images/jobsIcon.webp";
const proxy = process.env.REACT_APP_PROXY;
function AvailableJobs() {
  const [availablejoblist, setavailablejoblist] = useState([]);
  const [isloading, setIsloading] = useState(true);
  const [lastpage, setlastpage] = useState(0);

  useEffect(() => {
    axios.get(`${proxy}/joblist`).then((res, err) => {
      if (err) {
        console.log(err);
      } else {
        setavailablejoblist(res.data.data);
        setlastpage(res.data.last_page);
        setIsloading(false);
      }
    });
  }, []);

  if (isloading) {
    return <div>Loading...</div>;
  }
  return (
    <Fragment>
      <div className="page__bg d-block available--">
        <Container>
          <p className="pageSection__title pt-5">Available Jobs</p>
          <p className="greyBar"></p>

          <div className="jobCategory__checkBoxSection availableJobs__categorySection mt-4">
            {/* <Row className="filter_section">
                <Col lg="4" md="4" sm="12">
                  <div className="p-4 jobCategory__checkBoxSection--responsive jobCategory__checkBoxSection--FirstSectionResponsive">
                    <input type="checkbox" />
                    <label className="checkbox__label">Finance</label>
                    <br />

                    <input type="checkbox" />
                    <label className="checkbox__label"> Accounting </label>
                    <br />

                    <input type="checkbox" />
                    <label className="checkbox__label"> Supply Chain </label>
                    <br />
                  </div>
                </Col>

                <Col lg="4" md="4" sm="12">
                  <div className="p-4 jobCategory__checkBoxSection--responsive ">
                    <input type="checkbox" />
                    <label className="checkbox__label"> Trade Marketing</label>
                    <br />

                    <input type="checkbox" />
                    <label className="checkbox__label">
                      {" "}
                      Business Management{" "}
                    </label>
                    <br />

                    <input type="checkbox" />
                    <label className="checkbox__label"> Marketing </label>
                    <br />
                  </div>
                </Col>

                <Col lg="4" md="4" sm="12">
                  <div className="p-4 jobCategory__checkBoxSection--responsive">
                    <input type="checkbox" />
                    <label className="checkbox__label"> Sales</label>
                    <br />

                    <input type="checkbox" />
                    <label className="checkbox__label"> HR </label>
                    <br />
                  </div>
                </Col>
              </Row> */}

            <Row className="m-sm-5 justify-content-around">
              {availablejoblist.length === 0 && <h4>No Available Job.</h4>}
              {availablejoblist.map((job) => (
                <Col lg="4" md="6" sm="12">
                  <div className="avaiablejobs__jobCard">
                    <div className="w-25">
                      <img
                        className="avaiablejobs__jobIcon w-100 h-100"
                        src={`https://talentracker.com.bd/project/public/images/${job.company_logo}`}
                        alt="jobIcon"
                      />
                    </div>
                    <div className="w-75 ms-2">
                      <div className="avaiablejobs__jobDes ">
                        <p className="availableJobs__jobTitle">
                          {job.job_title}
                        </p>
                        <p className="availableJobs__jobCategory">
                          {job.category}
                        </p>
                        <Link
                          to={`/JobDetailsPage/${job.id}`}
                          className="availableJobs__btn details__btn mt-2"
                        >
                          View Details
                        </Link>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>

            {/* <Row>
              <Pagination className="talentTracker__pagination mt-5">
                <Pagination.First />
                <Pagination.Prev />
                <Pagination.Item>{1}</Pagination.Item>
                <Pagination.Ellipsis />

                <Pagination.Item>{10}</Pagination.Item>
                <Pagination.Item>{11}</Pagination.Item>
                <Pagination.Item active>{12}</Pagination.Item>
                <Pagination.Item>{13}</Pagination.Item>
                <Pagination.Item disabled>{14}</Pagination.Item>

                <Pagination.Ellipsis />
                <Pagination.Item>{20}</Pagination.Item>
                <Pagination.Next />
                <Pagination.Last />
              </Pagination>
            </Row> */}
          </div>
        </Container>
      </div>
    </Fragment>
  );
}

export default AvailableJobs;
