import React from "react";
import { Link } from "react-router-dom";
import BackgroundVideo from "../public/assets/media/GULSHAN 2 Footage for Website.mp4";
import "../public/assets/scss/Banner.css";

function Banner() {
  return (
    <section id="banner" className="position-relative banner">
      <div className="banner__video">
        <video className="w-100" autoPlay loop muted>
          <source src={BackgroundVideo} />
        </video>
      </div>
      <div className="banner__overlay position-absolute top-50 start-50 translate-middle text-light d-flex justify-content-center  align-items-center">
        <div className="text-center">
          <h3 className="banner__title">TalenTracker Limited</h3>
          <h6 className="banner__description">
            Creating Shared Experiences !!
          </h6>
          <button className="px-3 py-1 border-0 banner__button">
            <Link className="text-light" to="/AboutTalentTrackerPage">
              Read More
            </Link>
          </button>
        </div>
      </div>
    </section>
  );
}

export default Banner;
