import axios from "axios";
import React, { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function ConsultantAndCareerAdvisorReg() {
  const navigate = useNavigate();
  const proxy = process.env.REACT_APP_PROXY;

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [specilist, setSpecilist] = useState("");
  var formData = new FormData();
  function form_submit() {
    formData.append("name", document.getElementsByName("name")[0].value);
    formData.append("address", document.getElementsByName("address")[0].value);

    formData.append(
      "company_name",
      document.getElementsByName("company_name")[0].value
    );

    formData.append("email", document.getElementsByName("email")[0].value);
    formData.append(
      "password",
      document.getElementsByName("password")[0].value
    );

    formData.append("phone", document.getElementsByName("phone")[0].value);
    formData.append(
      "designation",
      document.getElementsByName("designation")[0].value
    );
    formData.append(
      "specilist_field",
      document.getElementsByName("specilist_field")[0].value
    );
    formData.append(
      "linkedin",
      document.getElementsByName("linkedin")[0].value
    );
    formData.append(
      "about_you",
      document.getElementsByName("about_you")[0].value
    );
    formData.append("role", document.getElementsByName("role")[0].value);
    formData.append(
      "specilist_image",
      document.getElementById("specilist_image").files[0]
    );

    for (var value of formData.values()) {
      console.log(value);
    }
    axios
      .post(`${proxy}/advisor`, formData)
      .then((res, err) => {
        console.log(res);
        if (err) {
          console.log(err);
        } else {
          window.Email.send({
            SecureToken: process.env.REACT_APP_SECURETOKEN,
            To: email,
            From: process.env.REACT_APP_EMAIL_FROM,
            Subject: "From Talentracker Official Site",
            Body: `Your have succesfully registered with Talentracker. <br/>
            Name : ${name} <br>
            Email : ${email} <br>
            Phone : ${phone} <br>
            Specialization : ${specilist} <br>
            Thank you.`,
          });
          window.Email.send({
            SecureToken: process.env.REACT_APP_SECURETOKEN,
            To: "care@talentracker.com.bd",
            From: process.env.REACT_APP_EMAIL_FROM,
            Subject: "New Consultant/Advisor Registration",
            Body: `
            Name : ${name} <br>
            Email : ${email} <br>
            Phone : ${phone} <br>
            Specialization : ${specilist} <br>
            `,
          });
          console.log(res);
          navigate("/");
        }
      })
      .catch((err) => {
        document.getElementById("warning").innerHTML =
          "Something Went Wrong.Please Try Again With Unique Email ";
      });
  }

  return (
    <>
      <Container>
        <p className="pageSection__title pt-5">Consultant / Career Advisor</p>
        <p className="greyBar"></p>
        <Form
          className="mt-4"
          onSubmit={(event) => {
            event.preventDefault();
            form_submit();
          }}
        >
          <Row xs={1} sm={1} md={2}>
            <Col>
              <Form.Group>
                <Form.Label className="from__label clientReg__formLabel">
                  Name
                </Form.Label>
                <Form.Control
                  required
                  className="formArea formAreaBorder"
                  name="name"
                  type="text"
                  placeholder="Type Your Full Name"
                  onBlur={(e) => setName(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label className="from__label clientReg__formLabel">
                  Email
                </Form.Label>
                <Form.Control
                  required
                  className="formArea formAreaBorder"
                  name="email"
                  type="email"
                  placeholder="Type Your Email Address"
                  onBlur={(e) => setEmail(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row xs={1} sm={1} md={2}>
            <Col>
              <Form.Group>
                <Form.Label className="from__label clientReg__formLabel">
                  Password
                </Form.Label>
                <Form.Control
                  required
                  className="formArea formAreaBorder"
                  name="password"
                  type="password"
                  placeholder="Choose a Password"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label className="from__label clientReg__formLabel">
                  Re-Type Password
                </Form.Label>
                <Form.Control
                  required
                  className="formArea formAreaBorder"
                  type="password"
                  placeholder="Retype Password"
                />
              </Form.Group>
            </Col>
          </Row>

          <Row xs={1} sm={1} md={2}>
            <Col>
              <Form.Group>
                <Form.Label className="from__label clientReg__formLabel">
                  Mobile No.
                </Form.Label>
                <Form.Control
                  required
                  className="formArea formAreaBorder"
                  name="phone"
                  type="text"
                  placeholder="Enter Your Mobile No"
                  onBlur={(e) => setPhone(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label className="from__label clientReg__formLabel">
                  Address
                </Form.Label>
                <Form.Control
                  required
                  className="formArea formAreaBorder"
                  name="address"
                  type="text"
                  placeholder="Type Your Address"
                />
              </Form.Group>
            </Col>
          </Row>

          <Row xs={1} sm={1} md={2}>
            <Col>
              <Form.Group>
                <Form.Label className="from__label clientReg__formLabel">
                  Company Name
                </Form.Label>
                <Form.Control
                  required
                  className="formArea formAreaBorder"
                  name="company_name"
                  type="text"
                  placeholder="Type Your Company Name"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label className="from__label clientReg__formLabel">
                  Designation
                </Form.Label>
                <Form.Control
                  required
                  className="formArea formAreaBorder"
                  name="designation"
                  type="text"
                  placeholder="Type Your Designation"
                />
              </Form.Group>
            </Col>
          </Row>

          <Row xs={1} sm={1} md={2}>
            <Col>
              <Form.Group>
                <Form.Label className="from__label clientReg__formLabel">
                  Specialist Field
                </Form.Label>
                <Form.Control
                  required
                  className="formAreaBorder formArea__dropDown"
                  name="specilist_field"
                  as="select"
                  onBlur={(e) => setSpecilist(e.target.value)}
                  defaultValue="Marketing"
                >
                  <option>Sales and Distribution</option>
                  <option>Marketing</option>
                  <option>Digital Marketing</option>
                  <option>Software Development</option>
                  <option>Project Management</option>
                  <option>Human Resources</option>
                  <option>Accounts and Finance</option>
                  <option>Supply Chain/Commercial</option>
                  <option>Logistics and Warehouse</option>
                  <option>E-Commerce</option>
                  <option>Ride Sharing</option>
                  <option>Administration</option>
                  <option>Banking</option>
                  <option>Investment Banking</option>
                  <option>Customer Service</option>
                  <option>Relationship Management</option>
                  <option>Business Development</option>
                  <option>Research and Development</option>
                  <option>Skill Development</option>
                  <option>IELTS Specialist</option>
                  <option>Big Data, IoT and AI</option>
                  <option>IT Security </option>
                  <option>IT Project Management</option>
                  <option>Civil Project Management</option>
                  <option>Contract Management</option>
                  <option>Leadership Development</option>
                  <option>Capability Development</option>
                  <option>Childhood Development</option>
                  <option>Nutritionist</option>
                  <option>Personal Tax Planning</option>
                  <option>VAT and Tax Management</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label className="from__label clientReg__formLabel">
                  Linkedin Profile
                </Form.Label>
                <Form.Control
                  required
                  className="formArea formAreaBorder"
                  type="text"
                  name="linkedin"
                  placeholder="Enter Your Linkedin Profile URL"
                />
              </Form.Group>
            </Col>
          </Row>

          <Form.Group className="clientRegFrom__address">
            <Form.Label className="from__label clientReg__formLabel mt-2">
              About Yourself
            </Form.Label>
            <Form.Control
              required
              className="formArea formAreaBorder"
              type="text"
              name="about_you"
              placeholder="Write About Yourself"
              as="textarea"
              rows={3}
            />
          </Form.Group>

          <Form.Group className="clientRegFrom__address">
            <Form.Label className="from__label clientReg__formLabel mt-3">
              Upload Image
            </Form.Label>
            <Form.Control
              required
              className="formArea formAreaFileUpload"
              type="file"
              name="specilist_image"
              id="specilist_image"
            />
            <p className="fileUpload__note">
              *Max file size 2MB, Regulation: 450px, X 600px
            </p>
          </Form.Group>

          <div className="mt-5">
            <label className="checkbox__label checkbox__boxItem me-2">
              User Type
            </label>

            <select className="option_css" name="role">
              <option value="3">Consultant / Industry Expert</option>
              <option value="4">Career Advisor</option>
              <option value="5">Trainer</option>
            </select>
          </div>

          {/* <Row>
            <Col lg="12" md="12" sm="12">
              <div className="mt-5 warning">
                <label className="checkbox__label" id="warning"></label>
                <br />
              </div>
            </Col>
          </Row> */}

          <Row className="mt-4">
            <Col lg="6" md="6" sm="12">
              <div className="d-flex align-items-center mb-sm-3">
                <input type="checkbox" required />
                <label className="checkbox__label ms-2">
                  {" "}
                  I agree to the Talent Tracker Terms of Use. Terms & Conditions
                </label>
                <br />
              </div>
            </Col>

            <Col lg="6" md="6" sm="12">
              <div>
                <Button
                  className="grdiantBtn freelanceRecruiterReg__btn"
                  type="submit"
                >
                  {" "}
                  REGISTRATION{" "}
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
}

export default ConsultantAndCareerAdvisorReg;
