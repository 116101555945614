import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function CreateAccount() {
  const navigate = useNavigate();
  const proxy = process.env.REACT_APP_PROXY;

  const [cv_image, setCv_image] = useState(null);
  const [specilist_image, setSpecilist_image] = useState(null);
  const [gender, setGender] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [skill, setSkill] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [facebookUrl, setFacebookUrl] = useState("");
  const [linkedin, setLinkedin] = useState("");

  let formData = new FormData();

  function submit_reg_form(e) {
    e.preventDefault();
    formData.append("cv_image", cv_image);
    formData.append("specilist_image", specilist_image);
    formData.append("gender", gender);
    formData.append("name", name);
    formData.append("email", email);
    formData.append("skill", skill);
    formData.append("phone", phone);
    formData.append("password", password);
    formData.append("facebook_url", facebookUrl);
    formData.append("linkedin", linkedin);
    axios
      .post(`${proxy}/jobseeker`, formData)
      .then((res, err) => {
        if (err) {
          console.log(err);

          document.getElementById("warning").innerHTML =
            "Something Went Wrong. Please Try Again Later.";
        } else {
          window.Email.send({
            SecureToken: process.env.REACT_APP_SECURETOKEN,
            To: res.data.email,
            From: process.env.REACT_APP_EMAIL_FROM,
            Subject: "From Talentracker Official Site",
            Body: `Your have succesfully registered with Talentracker. <br/>
                  Name : ${res.data.name} <br>
                  Email : ${res.data.email} <br>
                  Phone : ${res.data.phone} <br>
                  Skill : ${res.data.skill} <br>
                  Thank you.`,
          });
          window.Email.send({
            SecureToken: process.env.REACT_APP_SECURETOKEN,
            To: "care@talentracker.com.bd",
            From: process.env.REACT_APP_EMAIL_FROM,
            Subject: "New Jobseeker Registration",
            Body: `
                  Name : ${res.data.name} <br>
                  Email : ${res.data.email} <br>
                  Phone : ${res.data.phone} <br>
                  Skill : ${res.data.skill} <br>
                  `,
          });
          navigate("/availableJobs");
        }
      })
      .catch((err) => {
        console.log(err);
        document.getElementById("warning").innerHTML =
          "Something Went Wrong.Please Try Again With an Unique Email";
      });
  }

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <>
      <Container className="py-5">
        <Form onSubmit={submit_reg_form}>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Type your full name"
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicGender">
                <Form.Label>Gender</Form.Label>
                <div className="d-flex">
                  <div className="_gender-item_">
                    <input
                      required
                      className="createAccount__radioBtn"
                      type="radio"
                      name="gender"
                      value="male"
                      id="male"
                      onChange={(e) => setGender(e.target.value)}
                    />
                    <label for="male" className="createAccount__radioBtnLabel">
                      Male
                    </label>
                  </div>
                  <div className="_gender-item_">
                    <input
                      required
                      className="createAccount__radioBtn"
                      type="radio"
                      name="gender"
                      value="female"
                      id="female"
                      onChange={(e) => setGender(e.target.value)}
                    />
                    <label
                      for="female"
                      className="createAccount__radioBtnLabel"
                    >
                      Female
                    </label>
                  </div>
                  <div className="_gender-item_">
                    <input
                      required
                      className="createAccount__radioBtn"
                      type="radio"
                      name="gender"
                      value="others"
                      id="others"
                      onChange={(e) => setGender(e.target.value)}
                    />
                    <label
                      for="others"
                      className="createAccount__radioBtnLabel"
                    >
                      Others
                    </label>
                  </div>
                </div>
              </Form.Group>
            </Col>
          </Row>

          <Form.Group className="mb-3" controlId="formBasicSkills">
            <Form.Label>Select Your Skill From Following List</Form.Label>
            <input
              required
              list="oka"
              name="skill"
              className="d-block w-100"
              onChange={(e) => setSkill(e.target.value)}
            />
            <datalist id="oka">
              <option value="Sales and Distribution" />
              <option value="Marketing" />
              <option value="Digital Marketing" />
              <option value="Software Development" />
              <option value="Project Management" />
              <option value="Human Resources" />
              <option value="Accounts and Finance" />
              <option value="Supply Chain/Commercial" />
              <option value="Logistics and Warehouse" />
              <option value="E-Commerce" />
              <option value="Ride Sharing" />
              <option value="Administration" />
              <option value="Banking" />
              <option value="Investment Banking" />
              <option value="Customer Service" />
              <option value="Relationship Management" />
              <option value="Business Development" />
              <option value="Research and Development" />
              <option value="Skill Development" />
              <option value="IELTS Specialist" />
              <option value="Big Data, IoT and AI" />
              <option value="IT Security" />
              <option value="IT Project Management" />
              <option value="Civil Project Management" />
              <option value="Contract Management" />
              <option value="Leadership Development" />
              <option value="Capability Development" />
              <option value="Childhood Development" />
              <option value="Nutritionist" />
              <option value="Personal Tax Planning" />
              <option value="VAT and Tax Management" />
            </datalist>
          </Form.Group>

          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Type Your Email Address"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicMobile">
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control
                  type="text"
                  name="phone"
                  placeholder="+880 xxxx xxxxxx"
                  onChange={(e) => setPhone(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicPassword1">
                <Form.Label>
                  Password (Use at least 8 to 12 characters)
                </Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicPassword2">
                <Form.Label>Confirm Password </Form.Label>
                <Form.Control type="password" placeholder="Confirm Password" />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicFacebook">
                <Form.Label>Facebook URL</Form.Label>
                <Form.Control
                  type="text"
                  name="facebook_url"
                  placeholder="Type Facebook URL"
                  onChange={(e) => setFacebookUrl(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicLinkedin">
                <Form.Label>Linked in URL</Form.Label>
                <Form.Control
                  type="text"
                  name="linkedin"
                  placeholder="Type Linkedin URL"
                  onChange={(e) => setLinkedin(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Form.Group className="clientRegFrom__address">
            <Form.Label className="from__label clientReg__formLabel mt-3">
              Upload Your CV
            </Form.Label>
            <Form.Control
              required
              className="formArea formAreaFileUpload"
              type="file"
              name="cv_image"
              id="cv_image"
              onChange={(e) => setCv_image(e.target.files[0])}
            />
            <p className="fileUpload__note">*Choose PDF File</p>
          </Form.Group>

          <Form.Group className="clientRegFrom__address">
            <Form.Label className="from__label clientReg__formLabel mt-3">
              Upload Image
            </Form.Label>
            <Form.Control
              required
              className="formArea formAreaFileUpload"
              type="file"
              name="profile_image"
              id="profile_image"
              onChange={(e) => setSpecilist_image(e.target.files[0])}
            />
            <p className="fileUpload__note">
              *Max file size 2MB, Regulation: 450px, X 600px
            </p>
          </Form.Group>

          <Row>
            <Col lg="6" md="6" sm="12">
              <div className="mt-5">
                <input required type="checkbox" />
                <label className="checkbox__label ms-2">
                  {" "}
                  I agree to the Talent Tracker Terms of Use. Terms & Conditions
                </label>
                <br />
              </div>
            </Col>

            <Col lg="6" md="6" sm="12">
              <div className="mt-5">
                <Button
                  className="grdiantBtn freelanceRecruiterReg__btn"
                  type="submit"
                >
                  SUBMIT
                </Button>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12" md="12" sm="12">
              <div className="mt-5 warning">
                <label className="checkbox__label" id="warning"></label>
                <br />
              </div>
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
}

export default CreateAccount;
