import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import FooterLogo from "../public/assets/images/footerLogo.png";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { GoLocation } from "react-icons/go";
import { FiMail } from "react-icons/fi";
import { AiFillLinkedin } from "react-icons/ai";
import { AiFillFacebook } from "react-icons/ai";
import "../public/assets/scss/Footer.css";

function Footer() {
  return (
    <footer className="pt-5 mt-5 bg-dark text-light">
      <Container>
        <Row xs={1} sm={2} md={3}>
          <Col>
            <div>
              <img className="image" src={FooterLogo} alt="Footer Logo" />
              <p className="my-3">
                TalenTracker Limited is a full service Human Resources Business
                Partnering and Consultancy firm.
              </p>
              <div style={{ fontSize: "15px" }}>
                <span>
                  <GoLocation />
                </span>
                House-1(2nd Floor), Road-3, Block-A, Mirpur-11, Begum Rokeya
                Avenue, Dhaka-1216
              </div>
              <div>
                <span>
                  <AiOutlineWhatsApp />
                </span>
                <a href="tel:+8801847293000">+8801847293000</a>
              </div>
              <div>
                <span>
                  <FiMail />
                </span>
                <a href="mailto:youremail@domain.com">
                  care@talentracker.com.bd
                </a>
              </div>
            </div>
          </Col>
          <Col className="mt-5 mt-sm-2 mt-xs-2 pt-5">
            <h4>Opening Hours</h4>
            <div className="mt-2">
              <h6>SUN-THU:</h6>
              <p>09:00 am - 6:00 pm</p>
            </div>
          </Col>
          <Col className="mt-5 mt-sm-2 mt-xs-2 pt-5">
            <h4>Useful Links</h4>
            <ul className="list-unstyled">
              <li>
                <Link to="/AboutTalentTrackerPage">About us</Link>
              </li>
              <li>
                <Link to="/service">Services</Link>
              </li>
              <li>
                <Link to="/training">Training</Link>
              </li>
              <li>
                <Link to="/availableJobs">Jobs</Link>
              </li>
              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
      <div className="p-3 mt-4 bottom">
        <Container>
          <div className="d-flex justify-content-between">
            <p className="mb-0 copy">
              © 2022 talentracker . All Rights Reserved.
            </p>
            <div className="social">
              <a
                href="https://www.facebook.com/talentracker/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiFillFacebook className="fs-5" />
              </a>
              <a href="https://bd.linkedin.com/company/talentracker-limited">
                <AiFillLinkedin className="fs-5" />
              </a>
            </div>
            <div className="creator">
              <span>
                Design by{" "}
                <a
                  target="_blank"
                  href="https://halcyonbd.com/"
                  rel="noopener noreferrer"
                >
                  Halcyon Digital
                </a>
              </span>
            </div>
          </div>
        </Container>
      </div>
    </footer>
  );
}

export default Footer;
