import React from 'react';
import '../public/assets/scss/Management.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import Management1 from '../../src/public/assets/images/menagement.jpg';
import { Col, Container, Row } from 'react-bootstrap';

function Management() {
  const settings = {
    dots: true,
    infinite: true,
    arrows: true,
    speed: 500,
    adaptiveHeight: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  return (
    <section id="management" className="management pt-5">
      <Container>
        <h2 className="management__title mb-3">Management Credentials</h2>
        <p className="management__description">
          The TalenTracker management team is experienced with diversified skill
          set from different functions and industries. The range of experiences
          and competencies are enriched with 14 years of human resources and
          business management, 10 years of marketing, 12 years of sales
          management, 7 years of data analysis and 10 plus years of legal and
          corporate affairs experiences.
        </p>
        <p className="management__description">
          With the above skills and competencies, the TalenTracker team is
          capable of managing the recruitment for any industry, large teams
          under payroll operation, sales operation, offering consultancy
          solutions for almost every functions of the company and business
          management as whole and also offering customized training programs for
          all functions in collaboration with industry experts and own
          resources.
        </p>
        <p className="management__description">
          The team is also well equipped personal level consultancy for career
          planning, tax management and financial planning. At the same time,
          TalenTracker is also equipped with an extensive number of freelance
          consultants, career advisor and industry experts.
        </p>

        <div className="my-3">
          <Slider className="mt-4" {...settings}>
            {/*             <div className="management__item px-2">
              <div className="border">
                <Row>
                  <Col xs={3}>
                    <img src={Management1} alt="" className="w-100" />
                  </Col>
                  <Col xs={9}>
                    <p className="management__des px-1">
                      Mr. Prodip Das is currently working as Managing Director
                      of Rohto-Mentholatum (Bangladesh) Limited and he also
                      holds a role in International Business Division of Rohto
                      Pharmaceuticals (Japan). He has recently been appointed as
                      Specialist, Japanese SME Overseas Expansion Assistance
                      Platform, JETRO Dhaka and served as Vice President,
                      Japanese Commerce & Industry Association in Dhaka.
                    </p>
                  </Col>
                </Row>
              </div>
              <h5 className="management__name">Prodip Das</h5>
              <p className="management__deg">International Advisor</p>
            </div> */}

            <div className="management__item px-2">
              <div className="border">
                <Row>
                  <Col xs={3}>
                    <img src={Management1} alt="management" className="w-100" />
                  </Col>
                  <Col xs={9}>
                    <p className="management__des px-1">
                      An HR & Business management professional with 15 years of
                      proven track records across multiple industries including
                      Pharmacuticals, Life Insurance, Retail, Bank and FMCG.
                      Experienced in talent sourcing & management, HR
                      operations, Labour Law compliance, Administration,
                      Corporate Affairs, Sales & Distribution, Product value
                      chain management, Commercial imports and business planning
                      and management. Gained hands on experience in setting up
                      multinational company in Bangladesh.
                    </p>
                  </Col>
                </Row>
              </div>
              <h5 className="management__name">Shafiqul Islam</h5>
              <p className="management__deg">MD & CEO</p>
            </div>
          </Slider>
        </div>
      </Container>
    </section>
  );
}

export default Management;
