import React from "react";
import { AiFillFacebook, AiFillLinkedin } from "react-icons/ai";

function SocialIcon() {
  return (
    <div className="social-icon">
      <div className="icon">
        <a href="https://www.facebook.com/talentracker/">
          <AiFillFacebook className="facebook" />
        </a>
      </div>
      <div className="icon">
        <a href="https://bd.linkedin.com/company/talentracker-limited">
          <AiFillLinkedin className="whatsapp" />
        </a>
      </div>
    </div>
  );
}

export default SocialIcon;
