import React, { Component, Fragment } from "react";
import { Col, Container, Row } from "react-bootstrap";

class AboutTalentTracker extends Component {
  render() {
    return (
      <Fragment>
        <div className="page__bg">
          <Container>
            <Row>
              <Col lg="12" md="12" sm="12">
                <p className="pageSection__title pt-5 mb-0">About Us</p>
              </Col>
            </Row>

            {/* <div className="serviceDetails__titleBg ">
              <p className="serviceDetails__title mb-1">About Us</p>
            </div> */}

            <p className="aboutTalentTracker__text">
              TalenTracker Limited is a full-service Human Resources Business
              Partnering and Consultancy firm. The primary area of services
              includes Talent sourcing and acquisition partnering, Training,
              Management, and Legal Consultancy, Career Coaching Consultancy, HR
              Outsourcing, payroll management, and business support services.
              TalenTracker also aims to contribute to society by supporting
              potential professionals by counseling them to choose the
              appropriate career path for them.
            </p>

            <Row className="mt-4" lg={2} xs={1}>
              <Col className="p-2">
                <div className="about__talentracker p-2">
                  <div className="serviceDetails__titleBg ">
                    <p className="serviceDetails__title">MISSION</p>
                  </div>
                  <p className="aboutTalentTracker__text italic">
                    To be a professional, enthusiastic and innovative service
                    firm, dedicated to providing professional HR Consulting
                    services and evolving Solutions that help our customers
                    become more productive.
                  </p>
                </div>
              </Col>
              <Col className="p-2">
                <div className="about__talentracker p-2">
                  <div className="serviceDetails__titleBg ">
                    <p className="serviceDetails__title">VISION</p>
                  </div>
                  <p className="aboutTalentTracker__text italic">
                    To be the benchmark service provider in the areas of
                    business we operate by ensuring reliable, appropriate,
                    accurate, compliant and ethical service delivery.
                  </p>
                </div>
              </Col>
            </Row>
            <div className="core-values text-center serviceDetails__titleBg pt-2">
              <p className="core-parent about__talentracker p-4 d-inline serviceDetails__title mb-1">
                Core Values
              </p>
            </div>
            <div className="mt-3 px-sm-5">
              <Row lg={5} xs={1}>
                <Col className="p-2">
                  <div className="core-item p-2">
                    <div className="serviceDetails__titleBg ">
                      <p className="core-header">Committed</p>
                    </div>
                    <p className="aboutTalentTracker__text ">
                      We are committed to deliver superior services with
                      integrity, trust and appreciation to maintain customer
                      loyalty.
                    </p>
                  </div>
                </Col>
                <Col className="p-2">
                  <div className="core-item p-2">
                    <div className="serviceDetails__titleBg ">
                      <p className="core-header">Excellence</p>
                    </div>
                    <p className="aboutTalentTracker__text">
                      We strive for excellence and quality in everything we do
                      and deliver client-focused solutions that exceed
                      expectations.
                    </p>
                  </div>
                </Col>
                <Col className="p-2">
                  <div className="core-item p-2">
                    <div className="serviceDetails__titleBg ">
                      <p className="core-header">Integrity</p>
                    </div>

                    <p className="aboutTalentTracker__text ">
                      We will act with honesty and integrity and practice the
                      highest ethical standards.
                    </p>
                  </div>
                </Col>
                <Col className="p-2">
                  <div className="core-item p-2">
                    <div className="serviceDetails__titleBg ">
                      <p className="core-header">Partnership</p>
                    </div>

                    <p className="aboutTalentTracker__text">
                      We will work in partnership with our clients through
                      enthusiasm to drive their business forward.
                    </p>
                  </div>
                </Col>
                <Col className="p-2">
                  <div className="core-item  p-2">
                    <div className="serviceDetails__titleBg">
                      <p className="core-header">Confidentiality</p>
                    </div>

                    <p className="aboutTalentTracker__text">
                      We are committed to ensure the confidentiality of the
                      information shared by each client.
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </Fragment>
    );
  }
}

export default AboutTalentTracker;
