import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { BiSearchAlt } from "react-icons/bi";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import Logo from "../public/assets/images/logo.png";
import "../public/assets/scss/Navigation.css";

function Navigation() {
  return (
    <>
      <Navbar className="navbar main" collapseOnSelect expand="lg">
        <Container>
          <Navbar.Brand href="#home">
            <HashLink to="/">
              <img src={Logo} alt="Logo" />
            </HashLink>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mx-auto menu">
              <Nav.Link as={Link} to="/">
                Home
              </Nav.Link>
              <Nav.Link as={Link} to="/service">
                Services
              </Nav.Link>
              <Nav.Link as={Link} to="/availableJobs">
                Available Jobs
              </Nav.Link>
              <Nav.Link as={Link} to="/training">
                Training
              </Nav.Link>
              <Nav.Link as={Link} to="/labourLaw">
                Labour Law
              </Nav.Link>
              <Nav.Link href="http://talentracker.com.bd/blog/">Blog</Nav.Link>
              <Nav.Link as={Link} to="/login">
                Login
              </Nav.Link>
            </Nav>
            <Nav>
              <Nav.Link className="text-light" href="#memes">
                <BiSearchAlt />
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Navigation;
