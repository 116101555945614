import React from "react";
import { Container } from "react-bootstrap";
import Slider from "react-slick";
import "../public/assets/scss/Clients.css";
import Client1 from "../public/assets/images/client Roadmap.png";
import Client2 from "../public/assets/images/client Rohto.png";
import Client3 from "../public/assets/images/client metlife.png";
import Client4 from "../public/assets/images/client glamifield.png";
import Client5 from "../public/assets/images/client gsk.png";
import Client6 from "../public/assets/images/client haier.png";
import Client7 from "../public/assets/images/client Hemas.png";
import Client8 from "../public/assets/images/client itl.png";
// import Client9 from "../public/assets/images/client Labaid.png";
import Client10 from "../public/assets/images/client labaidch.png";
import Client11 from "../public/assets/images/client man.png";
import Client12 from "../public/assets/images/client remark.png";
import Client13 from "../public/assets/images/client samsung.png";
import Client14 from "../public/assets/images/client skincafe.png";
import Client15 from "../public/assets/images/client nipa.png";
import Client16 from "../public/assets/images/client shajgo.png";
import Client17 from "../public/assets/images/commenrsial bank.png";
import Client18 from "../public/assets/images/bexmco.png";
import "../public/assets/scss/Partner.css";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";

function Clients() {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    arrows: false,
    speed: 500,
    slidesToShow: 10,
    slidesToScroll: 5,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 8,
          slidesToScroll: 4,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 4,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 3,
          arrows: true,
          dots: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 3,
          arrows: true,
          dots: false,
        },
      },
    ],
  };
  return (
    <section id="client" className="client pt-5 position-relative">
      <Container>
        <h2 className="client__title mb-3">Clients</h2>

        <Slider
          {...settings}
          // prevArrow={<FaArrowLeft />}
          // nextArrow={<FaArrowRight />}
        >
          <div className=" overflow-hidden">
            <img
              src={Client1}
              className="w-100 px-1 mx-1"
              alt="Client or Partner Logo"
            />
          </div>
          <div className=" overflow-hidden">
            <img
              src={Client2}
              className="w-100 px-1 mx-1"
              alt="Client or Partner Logo"
            />
          </div>
          <div className="overflow-hidden">
            <img
              src={Client3}
              className="w-100 px-1 mx-1"
              alt="Client or Partner Logo"
            />
          </div>
          <div className="overflow-hidden">
            <img
              src={Client4}
              className="w-100 px-1 mx-1"
              alt="Client or Partner Logo"
            />
          </div>
          <div className="overflow-hidden">
            <img
              src={Client5}
              className="w-100 px-1 mx-1"
              alt="Client or Partner Logo"
            />
          </div>
          <div className="overflow-hidden">
            <img
              src={Client6}
              className="w-100 px-1 mx-1"
              alt="Client or Partner Logo"
            />
          </div>
          <div className="overflow-hidden">
            <img
              src={Client7}
              className="w-100 px-1 mx-1"
              alt="Client or Partner Logo"
            />
          </div>
          <div className="overflow-hidden">
            <img
              src={Client8}
              className="w-100 px-1 mx-1"
              alt="Client or Partner Logo"
            />
          </div>
          {/* <div className="overflow-hidden">
            <img src={Client9} className="w-100 px-1 mx-1" alt="Client or Partner Logo" />
          </div> */}
          <div className="overflow-hidden">
            <img
              src={Client10}
              className="w-100 px-1 mx-1"
              alt="Client or Partner Logo"
            />
          </div>
          <div className="overflow-hidden">
            <img
              src={Client11}
              className="w-100 px-1 mx-1"
              alt="Client or Partner Logo"
            />
          </div>
          <div className="overflow-hidden">
            <img
              src={Client12}
              className="w-100 px-1 mx-1"
              alt="Client or Partner Logo"
            />
          </div>
          <div className="overflow-hidden">
            <img
              src={Client13}
              className="w-100 px-1 mx-1"
              alt="Client or Partner Logo"
            />
          </div>
          <div className="overflow-hidden">
            <img
              src={Client14}
              className="w-100 px-1 mx-1"
              alt="Client or Partner Logo"
            />
          </div>
          <div className="overflow-hidden">
            <img
              src={Client15}
              className="w-100 px-1 mx-1"
              alt="Client or Partner Logo"
            />
          </div>
          <div className="overflow-hidden">
            <img
              src={Client16}
              className="w-100 px-1 mx-1"
              alt="Client or Partner Logo"
            />
          </div>
          <div className="overflow-hidden">
            <img
              src={Client17}
              className="w-100 px-1 mx-1"
              alt="Client or Partner Logo"
            />
          </div>
          <div className="overflow-hidden">
            <img
              src={Client18}
              className="w-100 px-1 mx-1"
              alt="Client or Partner Logo"
            />
          </div>
        </Slider>
      </Container>
    </section>
  );
}

export default Clients;
