import React, { useEffect } from "react";
import About from "../components/About";
import Banner from "../components/Banner";
import Clients from "../components/Clients";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import Management from "../components/Management";
import NavigationHome from "../components/NavigationHome";
import RegisterAndLogin from "../components/RegisterAndLogin";
import Services from "../components/Services";
import SocialIcon from "../components/SocialIcon";

function HomePage() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <>
      <NavigationHome />
      <Banner />
      <About />
      <RegisterAndLogin />
      <Management />
      <Services />
      <Clients />
      <Contact />
      <SocialIcon />
      <Footer />
    </>
  );
}

export default HomePage;
