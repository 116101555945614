import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router } from "react-router-dom";
import RouteList from "./Routes/RouteList";
import "./public/assets/scss/Common.css";
import "./public/assets/scss/pages.css";
import "./public/assets/scss/Responsive.css";

function App() {
  return (
    <>
      <Router>
        <RouteList />
      </Router>
    </>
  );
}

export default App;
