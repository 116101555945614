import React, { Fragment, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
// import "../../asset/css/Labourlaw.css";
import axios from "axios";

function LabourLaw() {
  const [laws, setlaws] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [searchIteam, setsearchIteam] = useState("");
  const [pagenumber, setpagenumber] = useState(1);
  const proxy = process.env.REACT_APP_PROXY;

  //Load and Update with pagination
  useEffect(() => {
    const _laws = axios
      .get(`${proxy}/labor-law-data?page=${pagenumber}`)
      .then((res) => {
        if (res.data.data.length != 0) {
          res.data.data.map((d) => {
            setlaws((p) => {
              return [...p, d];
            });
          });
        }
        //Add More Data With Previous Product

        // setlaws(res.data.data);
        setisLoading(false);
      });
  }, [pagenumber]);

  function searchLaw(iteam) {
    const _laws = axios
      .get(`${proxy}/labor-law-search?search=${iteam}`)
      .then((res) => {
        // check if search result exist
        if (res.data.length > 0) {
          // Clear Previous Result
          setlaws([]);
          //Change the result value color and font and insert into laws storage
          res.data.map((d) => {
            d.Description = d.Description.replaceAll(
              iteam,
              `<b style="color:red;">${iteam}</b>`
            );
            setlaws((p) => {
              return [...p, d];
            });
          });
          setisLoading(false);
        }
      });
  }

  //

  if (isLoading) {
    return <div>Loading...</div>;
  }
  return (
    <Fragment>
      <div className="container">
        <div className="labour_law">
          <div className="row">
            <h1>Labour Law</h1>
            <div className="search-container">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  if (searchIteam.length > 0) {
                    searchLaw(searchIteam);
                  }
                }}
              >
                <input
                  type="text"
                  value={searchIteam}
                  onChange={(event) => {
                    setsearchIteam(event.target.value);
                  }}
                />
                <input
                  className="btn btn-secondary labour_law_btn"
                  type="submit"
                  value="Search"
                />
              </form>
            </div>
          </div>
          {laws.map((x) => (
            <div className="single_law">
              <p className="labour_law_title">
                Law {x.serial_No} {x.title}
              </p>
              <p className="greyBar"></p>
              <p
                className="labour_law_description"
                dangerouslySetInnerHTML={{
                  __html: x.Description.replaceAll("|", "<br />"),
                }}
              ></p>
            </div>
          ))}
          <div className="row">
            <span
              className="btn btn-secondary labour_law_btn"
              onClick={() => {
                setpagenumber(pagenumber + 1);
              }}
            >
              Load More
            </span>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default LabourLaw;
