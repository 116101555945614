import React, { useEffect } from "react";
import Footer from "../components/Footer";
import Navigation from "../components/Navigation";
import BusinessSupportServices from "../components/BusinessSupportServices";
import Contact from "../components/Contact";

function BusinessSupportServicesPage() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <>
      <Navigation />
      <BusinessSupportServices />
      <Contact />
      <Footer />
    </>
  );
}

export default BusinessSupportServicesPage;
