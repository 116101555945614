import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../public/assets/scss/About.css";

function About() {
  return (
    <section id="about" className="pt-4">
      <Container>
        <h2 className="title">About Us</h2>
        <p className="description">
          TalenTracker Limited is a full-service Human Resources Business
          Partnering and Consultancy firm. The primary area of services includes
          Talent sourcing and acquisition partnering, Training, Management, and
          Legal Consultancy, Career Coaching Consultancy, HR Outsourcing,
          payroll management, and business support services. TalenTracker also
          aims to contribute to society by supporting potential professionals by
          counseling them to choose the appropriate career path for them.
        </p>
        <Link className="button px-4 py-1" to="/AboutTalentTrackerPage">
          See More
        </Link>
      </Container>
    </section>
  );
}

export default About;
