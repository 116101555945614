import React from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import "../public/assets/scss/Login.css";

function Login() {
  return (
    <>
      <div className="page__bg">
        <Container className="py-5">
          <Row className="mt-5 loginRegistration__pageArea">
            <Col
              lg="6"
              md="6"
              sm="12"
              offset-lg="3"
              className="loginRegistration__signInSection offset-lg-3 offset-md-3 px-4"
            >
              <p className="pageSection__title loginRegistration__signInTitle pt-5 text-light">
                Sign In
              </p>
              <p className="whiteBarSignin"></p>

              <Form
                className="loginRegistration__formArea"
                action="https://talentracker.com.bd/project/login"
                method="POST"
              >
                <Form.Group>
                  <Form.Label className="input__label mt-2 ms-2 text-light">
                    Email
                  </Form.Label>
                  <Form.Control
                    className="bg-transparent border-2 text-light input__border"
                    type="email"
                    name="email"
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label className="input__label mt-2 ms-2 text-light">
                    Password
                  </Form.Label>
                  <Form.Control
                    className="bg-transparent text-light input__border"
                    type="password"
                    name="password"
                  />
                </Form.Group>

                <Row className="align-items-center">
                  <Col lg="6" md="6" sm="12">
                    <div>
                      <a className="text-light input__label">
                        Forgot User ID or Password
                      </a>
                    </div>
                  </Col>

                  <Col lg="6" md="6" sm="12">
                    <div className="mt-5 pb-5">
                      <Button
                        className="loginRegistration__signInBtn"
                        type="submit"
                      >
                        {" "}
                        Sign In{" "}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Login;
