import React, { Component, Fragment } from "react";
import { Col, Container, Row } from "react-bootstrap";
import BulletIconsImg from "../public/assets/images/bulletPoints.webp";

class LicensingServices extends Component {
  render() {
    return (
      <Fragment>
        <div className="page__bg">
          <Container>
            <Row>
              <Col lg="12" md="12" sm="12">
                <p className="pageSection__title pt-5">LICENSING SERVICES</p>
                <p className="greyBar"></p>
              </Col>
            </Row>

            <div className="serviceDetails__titleBg mt-2">
              <p className="serviceDetails__title">Our Services</p>
            </div>

            <ul className="serviceDetails__list mt-3">
              <li>
                {" "}
                <span className="serviceDetails__bullet">
                  <img src={BulletIconsImg} alt="bullet" />
                </span>{" "}
                Company/Firm/Sole Proprietorship Business Formation
              </li>
              <li>
                {" "}
                <span className="serviceDetails__bullet">
                  <img src={BulletIconsImg} alt="bullet" />
                </span>{" "}
                BIDA Approval{" "}
              </li>
              <li>
                {" "}
                <span className="serviceDetails__bullet">
                  <img src={BulletIconsImg} alt="bullet" />
                </span>{" "}
                Preparation of MoA & AoA, Partnership Deeds
              </li>
              <li>
                {" "}
                <span className="serviceDetails__bullet">
                  <img src={BulletIconsImg} alt="bullet" />
                </span>{" "}
                Supporting all types of commercial contract preparation
              </li>
              <li>
                {" "}
                <span className="serviceDetails__bullet">
                  <img src={BulletIconsImg} alt="bullet" />
                </span>{" "}
                BIN/TIN, IRC, ERC and Trade Mark Registration
              </li>
              <li>
                {" "}
                <span className="serviceDetails__bullet">
                  <img src={BulletIconsImg} alt="bullet" />
                </span>{" "}
                NOC from Local Govt. Authority
              </li>
              <li>
                {" "}
                <span className="serviceDetails__bullet">
                  <img src={BulletIconsImg} alt="bullet" />
                </span>{" "}
                Trade License
              </li>
              <li>
                {" "}
                <span className="serviceDetails__bullet">
                  <img src={BulletIconsImg} alt="bullet" />
                </span>{" "}
                Factory License
              </li>
              <li>
                {" "}
                <span className="serviceDetails__bullet">
                  <img src={BulletIconsImg} alt="bullet" />
                </span>{" "}
                Establishment License From DIFE
              </li>
              <li>
                {" "}
                <span className="serviceDetails__bullet">
                  <img src={BulletIconsImg} alt="bullet" />
                </span>{" "}
                Fire License
              </li>
              <li>
                {" "}
                <span className="serviceDetails__bullet">
                  <img src={BulletIconsImg} alt="bullet" />
                </span>{" "}
                Environmental License
              </li>
              <li>
                {" "}
                <span className="serviceDetails__bullet">
                  <img src={BulletIconsImg} alt="bullet" />
                </span>{" "}
                Pharmacy License
              </li>
              <li>
                {" "}
                <span className="serviceDetails__bullet">
                  <img src={BulletIconsImg} alt="bullet" />
                </span>{" "}
                LAB License
              </li>
              <li>
                {" "}
                <span className="serviceDetails__bullet">
                  <img src={BulletIconsImg} alt="bullet" />
                </span>{" "}
                Hospital License
              </li>
              <li>
                {" "}
                <span className="serviceDetails__bullet">
                  <img src={BulletIconsImg} alt="bullet" />
                </span>{" "}
                Narcotic Permission
              </li>
              <li>
                {" "}
                <span className="serviceDetails__bullet">
                  <img src={BulletIconsImg} alt="bullet" />
                </span>{" "}
                Boiler License
              </li>
            </ul>
          </Container>
        </div>
      </Fragment>
    );
  }
}

export default LicensingServices;
