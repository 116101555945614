import React, { useEffect } from "react";
import Footer from "../components/Footer";
import Navigation from "../components/Navigation";
import FreelanceConsultant from "../components/FreelanceConsultant";
import Contact from "../components/Contact";

function ConsultantPage() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div>
      <Navigation />
      <FreelanceConsultant />
      <Contact />
      <Footer />
    </div>
  );
}

export default ConsultantPage;
