import React from "react";
import { Route, Routes } from "react-router-dom";
import AvailableJobsPage from "../pages/AvailableJobsPage";
import ConsultantAndCareerAdvisorRegPage from "../pages/ConsultantAndCareerAdvisorRegPage";
import CreateAccountPage from "../pages/CreateAccountPage";
import FreelanceRecruiterRegPage from "../pages/FreelanceRecruiterRegPage";
import HomePage from "../pages/HomePage";
import JobDetailsPage from "../pages/JobDetailsPage";
import LabourLawPage from "../pages/LabourLawPage";
import LoginPage from "../pages/LoginPage";
import ServiceSectionPage from "../pages/ServiceSectionPage";
import TrainingDetailsPage from "../pages/TrainingDetailsPage";
import TrainingPage from "../pages/TrainingPage";
import HrServicePage from "../pages/HrServicePage";
import HrAndPayrollOutsourcingPage from "../pages/HrAndPayrollOutsourcingPage";
import TalentSearchAndOutplacementPage from "../pages/TalentSearchAndOutplacementPage";
import HrConsultancyServicesPage from "../pages/HrConsultancyServicesPage";
import CareerCoachingAndMentoringPage from "../pages/CareerCoachingAndMentoringPage";
import TrainingAndDevelopmentPage from "../pages/TrainingAndDevelopmentPage";
import HrAutomationAndHrisConsultancyPage from "../pages/HrAutomationAndHrisConsultancyPage";
import EmployeeBenefitSchemeDesignAndApprovalPage from "../pages/EmployeeBenefitSchemeDesignAndApprovalPage";
import BusinessSupportServicesPage from "../pages/BusinessSupportServicesPage";
import ExpatriateAffairsManagementPage from "../pages/ExpatriateAffairsManagementPage";
import LicensingServicesPage from "../pages/LicensingServicesPage";
import PersonalTaxManagementPage from "../pages/PersonalTaxManagementPage";
import AboutTalentTrackerPage from "../pages/AboutTalentTrackerPage";
import ConsultantPage from "../pages/ConsultantPage";
import JobSeekersPage from "../pages/JobSeekersPage";
import CareerAdvisorPage from "../pages/CareerAdvisorPage";
import ContactPage from "../pages/ContactPage";

function RouteList() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/availableJobs" element={<AvailableJobsPage />} />
      <Route path="/JobDetailsPage/:id" element={<JobDetailsPage />} />
      <Route path="/CreateAccountPage" element={<CreateAccountPage />} />
      <Route
        path="/ConsultantAndCareerAdvisorRegPage"
        element={<ConsultantAndCareerAdvisorRegPage />}
      />
      <Route
        path="/FreelanceRecruiterRegPage"
        element={<FreelanceRecruiterRegPage />}
      />
      <Route path="/training" element={<TrainingPage />} />
      <Route path="/labourLaw" element={<LabourLawPage />} />
      <Route path="/service" element={<ServiceSectionPage />} />
      <Route
        path="/TrainingDetailsPage/:id"
        element={<TrainingDetailsPage />}
      />
      <Route path="/hrServicePage" element={<HrServicePage />} />
      <Route
        path="/HrAndPayrollOutsourcingPage"
        element={<HrAndPayrollOutsourcingPage />}
      />
      <Route
        path="/TalentSearchAndOutplacementPage"
        element={<TalentSearchAndOutplacementPage />}
      />
      <Route
        path="/HrConsultancyServicesPage"
        element={<HrConsultancyServicesPage />}
      />
      <Route
        path="/CareerCoachingAndMentoringPage"
        element={<CareerCoachingAndMentoringPage />}
      />
      <Route
        path="/TrainingAndDevelopmentPage"
        element={<TrainingAndDevelopmentPage />}
      />
      <Route
        path="/HrAutomationAndHrisConsultancyPage"
        element={<HrAutomationAndHrisConsultancyPage />}
      />
      <Route
        path="/EmployeeBenefitSchemeDesignAndApprovalPage"
        element={<EmployeeBenefitSchemeDesignAndApprovalPage />}
      />
      <Route
        path="/BusinessSupportServicesPage"
        element={<BusinessSupportServicesPage />}
      />
      <Route
        path="/ExpatriateAffairsManagementPage"
        element={<ExpatriateAffairsManagementPage />}
      />
      <Route
        path="/LicensingServicesPage"
        element={<LicensingServicesPage />}
      />
      <Route
        path="/PersonalTaxManagementPage"
        element={<PersonalTaxManagementPage />}
      />
      <Route
        path="/AboutTalentTrackerPage"
        element={<AboutTalentTrackerPage />}
      />
      <Route path="/ConsultantPage" element={<ConsultantPage />} />
      <Route path="/JobSeekersPage" element={<JobSeekersPage />} />
      <Route path="/CareerAdvisorPage" element={<CareerAdvisorPage />} />
      <Route path="/contact" element={<ContactPage />} />
    </Routes>
  );
}

export default RouteList;
