import React, { useEffect } from "react";
import Footer from "../components/Footer";
import Navigation from "../components/Navigation";
import ExpatriateAffairsManagement from "../components/ExpatriateAffairsManagement";
import Contact from "../components/Contact";

function ExpatriateAffairsManagementPage() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div>
      <Navigation />
      <ExpatriateAffairsManagement />
      <Contact />
      <Footer />
    </div>
  );
}

export default ExpatriateAffairsManagementPage;
