import React, { useEffect } from "react";
import Footer from "../components/Footer";
import Navigation from "../components/Navigation";
import HrConsultancyServices from "../components/HrConsultancyServices";
import Contact from "../components/Contact";

function HrConsultancyServicesPage() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <>
      <Navigation />
      <HrConsultancyServices />
      <Contact />
      <Footer />
    </>
  );
}

export default HrConsultancyServicesPage;
