import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row, Pagination, Button } from "react-bootstrap";

class careerAdvisor extends Component {
  render() {
    return (
      <Fragment>
        <div className="page__bg">
          <Container>
            <p className="pageSection__title pt-5">
              {/* Consultant / Industry Expert */}
              Freelance Consultant
            </p>
            <p className="greyBar"></p>
            <p className="jobCategory__checkBoxSection_para">
              There are huge number of professionals who has in depth
              understanding across industries and functions and they are
              interested to share their experiences and expertise to make others
              organizations successful. But due to lack of knowledge or access
              to potential clients who requires the services for different
              functions, they remain disconnected form this huge world of
              opportunities. In this area, TalenTracker has come up with the
              opportunity to create consultant profiles here and TalenTracker
              will connect between consultants and potential clients.
              TalenTracker will undertake assignments and offer the registered
              consultants to work on project basis based on mutually beneficiary
              revenue sharing.
            </p>
            <Link to="/ConsultantAndCareerAdvisorRegPage">
              <button className="talentTracker__btn freelance__btn ">
                join us
              </button>
            </Link>
          </Container>
        </div>
      </Fragment>
    );
  }
}

export default careerAdvisor;
