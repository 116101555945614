import React from "react";
import { useEffect } from "react";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import Navigation from "../components/Navigation";

function ContactPage() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <Navigation />
      <Contact />
      <Footer />
    </>
  );
}

export default ContactPage;
