import React, { Component, Fragment } from "react";
import { Col, Container, Row } from "react-bootstrap";
import BulletIconsImg from "../public/assets/images/bulletPoints.webp";

class HrAutomationAndHrisConsultancy extends Component {
  render() {
    return (
      <Fragment>
        <div className="page__bg">
          <Container>
            <Row>
              <Col lg="12" md="12" sm="12">
                <p className="pageSection__title pt-5">
                  HR AUTOMATION & HRIS CONSULTANCY
                </p>
                <p className="greyBar"></p>
              </Col>
            </Row>

            <div className="serviceDetails__titleBg mt-2">
              <p className="serviceDetails__title">How Do We Work</p>
            </div>

            <ul className="serviceDetails__list mt-3">
              <li>
                {" "}
                <span className="serviceDetails__bullet">
                  <img src={BulletIconsImg} alt="bullet" />
                </span>{" "}
                Analysis & understanding the clients need
              </li>
              <li>
                {" "}
                <span className="serviceDetails__bullet">
                  <img src={BulletIconsImg} alt="bullet" />
                </span>{" "}
                Portray a blueprint to visualize{" "}
              </li>
              <li>
                {" "}
                <span className="serviceDetails__bullet">
                  <img src={BulletIconsImg} alt="bullet" />
                </span>{" "}
                Assign best available IT firm/our team for the project
              </li>
              <li>
                {" "}
                <span className="serviceDetails__bullet">
                  <img src={BulletIconsImg} alt="bullet" />
                </span>{" "}
                Assign project leader to support end to end
              </li>
            </ul>

            <div className="serviceDetails__titleBg mt-2">
              <p className="serviceDetails__title">Automation Services</p>
            </div>

            <ul className="serviceDetails__list mt-3">
              <li>
                {" "}
                <span className="serviceDetails__bullet">
                  <img src={BulletIconsImg} alt="bullet" />
                </span>{" "}
                Leave & Attendance Automation
              </li>
              <li>
                {" "}
                <span className="serviceDetails__bullet">
                  <img src={BulletIconsImg} alt="bullet" />
                </span>{" "}
                Payroll automation{" "}
              </li>
              <li>
                {" "}
                <span className="serviceDetails__bullet">
                  <img src={BulletIconsImg} alt="bullet" />
                </span>{" "}
                Performance Management Automaton
              </li>
              <li>
                {" "}
                <span className="serviceDetails__bullet">
                  <img src={BulletIconsImg} alt="bullet" />
                </span>{" "}
                Integration of all module into one software
              </li>
              <li>
                {" "}
                <span className="serviceDetails__bullet">
                  <img src={BulletIconsImg} alt="bullet" />
                </span>{" "}
                As required by clients
              </li>
            </ul>
          </Container>
        </div>
      </Fragment>
    );
  }
}

export default HrAutomationAndHrisConsultancy;
