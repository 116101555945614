import React from "react";
import "../public/assets/scss/RegisterAndLogin.css";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Image1 from "../public/assets/images/user.webp";
import Image2 from "../public/assets/images/career-advisor.png";
import Image3 from "../public/assets/images/freelance-recruiter.png";
import Image4 from "../public/assets/images/job-seekers.png";

function RegisterAndLogin() {
  return (
    <section id="resister__login" className="pt-5">
      <Container>
        <h2 className="title mb-3">Join Us</h2>

        <Row xs={1} sm={2} md={2} lg={4} xxl={4}>
          <Col className="my-sm-2 my-md-2 item border border-1">
            <div className="py-3 px-2">
              <div className="text-center mb-2">
                <img src={Image1} alt="" />
              </div>
              <h4 className="item__title">Consultant/Industry Expert</h4>
              <p className="item__description">
                There are huge number of professionals who has in depth
                understanding across industries and functions and they are
                interested to share their experiences and expertise to make
                others organizations successful...
                <span className="see">
                  <Link to="/ConsultantPage">read more</Link>
                </span>
              </p>
              <Link
                to="/ConsultantAndCareerAdvisorRegPage"
                className="item__button px-3"
              >
                Join
              </Link>
            </div>
          </Col>
          <Col className="my-sm-2 my-md-2 item border border-1">
            <div className=" py-3 px-2">
              <div className="text-center mb-2">
                <img src={Image2} alt="" />
              </div>
              <h4 className="item__title">Career Advisor</h4>
              <p className="item__description">
                This is another unique initiative by TalenTracker. Huge number
                of potential job seekers and entry level professionals remains
                in confusion about their career path and choices due to lack of
                access to professionals, industries and job market scenario...
                <span className="see">
                  <Link to="/CareerAdvisorPage">read more</Link>
                </span>
              </p>
              <Link
                to="/ConsultantAndCareerAdvisorRegPage"
                className="item__button px-3"
              >
                Join
              </Link>
            </div>
          </Col>
          <Col className="my-sm-2 my-md-2 item border border-1">
            <div className="item py-3 px-2">
              <div className="text-center mb-2">
                <img src={Image3} alt="" />
              </div>
              <h4 className="item__title">Freelance Consultant</h4>
              <p className="item__description">
                There are huge number of professionals who has in depth
                understanding across industries and functions and they are
                interested to share their experiences and expertise to make
                others organizations successful...
                <span className="see">
                  <Link to="/ConsultantPage">read more</Link>
                </span>
              </p>
              <Link
                to="/FreelanceRecruiterRegPage"
                className="item__button px-3"
              >
                Join
              </Link>
            </div>
          </Col>
          <Col className="my-sm-2 my-md-2 item border border-1">
            <div className="item py-3 px-2">
              <div className="text-center mb-2">
                <img src={Image4} alt="" />
              </div>
              <h4 className="item__title">Job Seekers</h4>
              <p className="item__description">
                There are huge number of professionals who has in depth
                understanding across industries and functions and they are
                interested to share their experiences and expertise to make
                others organizations successful...
                <span className="see">
                  <Link to="/JobSeekersPage">read more</Link>
                </span>
              </p>
              <Link to="/createAccountPage" className="item__button px-3">
                Join
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default RegisterAndLogin;
