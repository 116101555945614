import axios from "axios";
import React, { Component, Fragment } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Container, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
const proxy = process.env.REACT_APP_PROXY;
export default function TrainingDetails() {
  const { id } = useParams();
  const [trainingDetails, settrainingDetails] = useState([]);
  const [isloading, setIsloading] = useState(true);
  useEffect(() => {
    axios.get(`${proxy}/traininglist/${id}`).then((res, err) => {
      if (err) {
        console.log(err);
      } else {
        settrainingDetails(res.data);
        setIsloading(false);
      }
    });
  }, []);
  if (isloading) {
    return <div>Loading</div>;
  }
  return (
    <Fragment>
      <Container>
        <p className="pageSection__title pt-5">Training Details</p>
        <p className="greyBar"></p>
        <p className="mt-5 jobDetails__title">
          Training Title :{trainingDetails.training_title}
        </p>
        <p className="jobDetails__des">
          Training Category : {trainingDetails.training_category}
        </p>
        <p className="jobDetails__des">
          Name of Trainer : {trainingDetails.trainer_name}
        </p>
        <p className="jobDetails__des">
          Designation of Trainer : {trainingDetails.trainer_designation}
        </p>

        <iframe
          className="mt-5 jobDetails__pdfViewer"
          src="https://docs.google.com/viewer?url=http://www.pdf995.com/samples/pdf.pdf&embedded=true"
          frameBorder="0"
        />

        <div className="text-center mt-5">
          <Button className="grdiantBtn jobDetails__btn" type="submit">
            {" "}
            register now{" "}
          </Button>
        </div>
      </Container>
    </Fragment>
  );
}
